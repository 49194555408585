import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { CreateUserDto } from '../models/user/CreateUserDto';
import { Observable } from 'rxjs';
import { ProjectFilter } from '../models/project/project.filter';
import { CreateProjectDto } from '../models/project/CreateProjectDto';
import { MasterKeywordFilter } from '../models/master/MasterKeywordFilter';
import { MasterListDto } from '../models/master/MasterCollection';
import { AppConsts } from '../AppConsts';
import { MasterLocationKeywordFilter } from '../models/master/masterLocationKeywordFilter';
import { MemberListFilter, MemberListModel } from 'src/shared/models/team/member-list.model';
import { MasterDataKeywordFilter } from '../models/master/MasterDataKeywordFilter';
import { CategoryListKeywordFilter } from '../models/master/CategoryListDto';
import { MasterDataDto } from '../models/master/MasterDataDto';

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  wso2AmEndpoint = 'http://20.51.204.32:8280';
  serviceUrl = 'http://20.51.204.32:8280';
  constructor(
    private apiService: ApiService
  ) {
    this.wso2AmEndpoint = AppConsts.remoteServiceBaseUrl + "/app";
    this.serviceUrl = AppConsts.userServiceBaseUrl + "/app";
  }
  getMastersDatas(filters: MasterKeywordFilter): Observable<MasterListDto[]> {
    return this.apiService.post(`${this.wso2AmEndpoint}/master/get-masters-datas`, filters);
  }
  getMasterLocations(filters: MasterLocationKeywordFilter): Observable<MasterListDto[]> {
    return this.apiService.post(`${this.serviceUrl}/master/get-mst-location-datas`, filters);
  }
  memberContractorList(projectId: string, filters: MemberListFilter): Observable<MemberListModel[]> {
    return this.apiService.post(`${this.wso2AmEndpoint}/master/get-connection-contractor-member/${projectId}`, filters);
  }
  getMasterDetailDatas(filters: MasterDataKeywordFilter): Observable<MasterListDto[]> {
    return this.apiService.post(`${this.serviceUrl}/master/get-masters-datas`, filters);
  }

  getCategoryData(filters: CategoryListKeywordFilter): Observable<MasterDataDto[]> {
    return this.apiService.post(`${this.serviceUrl}/master/get-category-data`, filters);
  }

  getAllCity(): Observable<MasterListDto[]> {
    return this.apiService.get(`${this.serviceUrl}/master/city-list`);
  }

  getInviteBidderList(bidId: string, filters: MemberListFilter): Observable<MemberListModel[]> {
    return this.apiService.post(`${this.wso2AmEndpoint}/master/get-bidder-member/${bidId}`, filters);
  }

  getInviteBidderOrganizationList(bidId: string): Observable<MasterDataDto[]> {
    return this.apiService.get(`${this.wso2AmEndpoint}/master/bidder-organization/${bidId}`);
  }
}
