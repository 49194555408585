export enum MasterEnum {
  ProjectType = 'ProjectType',
  Location = 'Location',
  BidType = 'BidType',
  Users = 'Users',
  Project = 'Project',
  Bid = 'Bid',
  ProjectStatus = 'ProjectStatus',
  WorkOrderType = 'WorkOrderType',
  FilePrivacy = 'FilePrivacy',
  NetworkUser = 'NetworkUser',
  BusinessType = 'BusinessType',
  WorkType = 'WorkType',
  SizeType = 'SizeType',
  BidLead = 'BidLead',
  Currency = 'Currency',
  BidStatus = 'BidStatus',
  Organization = 'Organization',
  MandatoryEnum = 'MandatoryEnum',
  CheckList = 'CheckList'
}
