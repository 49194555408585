import { Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { request } from 'http';
import { filter, finalize } from 'rxjs/operators';
import { GetMessageModel } from 'src/shared/models/message/get-message.model';
import { CreateMessageDto } from 'src/shared/models/message/CreateMessageDto';
import { MessageFilter } from 'src/shared/models/message/message.filter';
import { UserConnectionInput, UserConnectionList } from 'src/shared/models/network/user-connection-list';
import { MessageService } from 'src/shared/services/message.service';
import { NetworkService } from 'src/shared/services/network.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {

  getMessagesList: GetMessageModel[] = [];
  connectionList: UserConnectionList[] = [];
  messageModel: CreateMessageDto;
  messageFilter: MessageFilter = new MessageFilter();
  messageForm: FormGroup;
  submitted = false;
  loading: boolean = false;
  toUserId: string;
  toUsername: string;
  toUserProfile: string;
  @Input() keyword: string;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  selectedUser: any;

  constructor(public networkService: NetworkService,
    public messageService: MessageService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    injector: Injector) {

  }

  ngOnInit(): void {
    this.getList();

    this.messageForm = this.formBuilder.group({
      subject: ['']
    })

  }

  getList(): void {
    const request: UserConnectionInput = new UserConnectionInput();
    request.keyword = this.keyword;
    this.networkService.list(request).subscribe(res => {
      this.connectionList = res.items;
      this.scrollToBottom();
    });
  }
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }
  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  getMessageList() {

    this.messageService.getMessageList(this.messageFilter).subscribe(res => {
      this.getMessagesList = res.items;

    });
  }

  getMesList(user) {
    this.selectedUser = user;
    this.messageFilter.toUserId = user.userId;
    this.toUserId = user.userId;
    this.toUsername = user.name + ' ' + user.surname;
    this.toUserProfile = user.profile;
    this.getMessageList();
  }


  onFormSubmit(): void {
    this.submitted = true;
    if (this.messageForm.get('subject').value == null || this.messageForm.get('subject').value.trim() == '') {
      this.snackBar.open('Please enter a value!', null, {
        duration: 5 * 1000,
        horizontalPosition: 'right',
        panelClass: 'success-snackbar'
      });
      return;
    }
    if (this.messageForm.invalid) {
      return;
    }
    this.loading = true;
    this.messageModel = new CreateMessageDto();
    this.messageModel.subject = this.messageForm.get('subject').value;
    this.messageModel.toUserId = this.toUserId;
    this.messageService.getToMessageList(this.messageModel)
      .subscribe(
        res => {
          this.loading = false;
          this.snackBar.open('Message Sent Successfully', null, {
            duration: 5 * 1000,
            horizontalPosition: 'right',
            panelClass: 'success-snackbar'
          });
          this.messageForm.reset();
          this.getMessageList();
        },
        err => {
          this.loading = false;
          this.snackBar.open(err.error.error.message, null, {
            duration: 5 * 1000,
            horizontalPosition: 'right',
            panelClass: 'error-snackbar'
          });
        });
  }
  get formControls(): any { return this.messageForm.controls; }


}
