import { PagedResultDto, PagedResultRequestDto } from "src/core/models/PagedResultDto";

export class GetUserBankModel {
    userId: string;
    userName: string;
    bankId: string;
    bankName: string;
    accountNumber: string;
    branch: string;
    ifsCode: string;
    id: string;
}
export class GetUserBankComponentModel {
    pagedResultDto: PagedResultDto<GetUserBankModel>;
}

export class BankDetailFilter extends PagedResultRequestDto {
    keyword: string;
}
