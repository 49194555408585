import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConsts } from 'src/core/AppConsts';
import { PagedResultDto } from 'src/core/models/PagedResultDto';
import { ResponseModel } from 'src/core/models/response/response.model';
import { ApiService } from 'src/core/services/api.service';
import { ValidateInvite } from '../models/network/validate-invite';
import { CreateUserDto } from '../models/user-list/CreateUserDto';
import { GetUserListModel, UserListComponentModel } from '../models/user-list/get-user-list.model';
import { UserFilter } from '../models/user-list/userList.filter';

@Injectable({
  providedIn: 'root'
})
export class UserListService {
  
  constructor(private apiService: ApiService) {
    this.wso2AmEndpoint = AppConsts.userServiceBaseUrl;
    this.remoteServiceBaseUrl = AppConsts.remoteServiceBaseUrl;
  }
  private wso2AmEndpoint: string;
  private remoteServiceBaseUrl: string;

  getUserList(filter: UserFilter = new UserFilter()): Observable<PagedResultDto<GetUserListModel>> {
    if (filter.keyword) {
      return this.apiService.get(`${this.wso2AmEndpoint}/app/identity-user/?Filter=${filter.keyword}&SkipCount=${filter.skipCount}&MaxResultCount=${filter.maxResultCount}`);
    } else {
      return this.apiService.get(`${this.wso2AmEndpoint}/app/identity-user/?SkipCount=${filter.skipCount}&MaxResultCount=${filter.maxResultCount}`);
    }
  }

  activeOrDeactive(userId: string, isActive: boolean): Observable<ResponseModel> {
    return this.apiService.post(`${this.wso2AmEndpoint}/app/identity-user/active-deactive-users/${userId}?isActive=${isActive}`);
  }

  getList(id: string): Observable<any> {
    return this.apiService.get(`${this.wso2AmEndpoint}/app/identity-user/${id}`);
  }

  registerNewUser(createUserDto: CreateUserDto) {
    return this.apiService.post(`${this.wso2AmEndpoint}/app/register-user/new-user`, createUserDto);
  }

  validate(input: ValidateInvite): Observable<boolean> {
    return this.apiService.post(`${this.wso2AmEndpoint}/validateToken/${input.inviteId}?token=${input.token}`);
  }

  importNewUser(file: File): any {
    let formData = new FormData();
    formData.append('file', file);
    return this.apiService.post(`${this.wso2AmEndpoint}/app/register-user/import-new-user`, formData);
  }

  userPackageDetail() {
    return this.apiService.get(`${this.remoteServiceBaseUrl}/app/package-app-services/current-user-package-details`);
  }
}
