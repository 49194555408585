<div class="card p-4 mb-0">
    <div class="card-header">
        <h4 class="card-title" style="color: #0077C2; font-size:large">Messages</h4>
    </div>
    <!-- <div class="chatheader">
          
    <div class="search-bar">
        <input type="text" placeholder="Search..." />
    </div>
    <div class="user-settings"> -->
    <!--   <div class="dark-light">
            <svg viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z" /></svg>
        </div> -->
    <!--        <div class="settings">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <circle cx="12" cy="12" r="3" />
                <path d="M19.4 15a1.65 1.65 0 00.33 1.82l.06.06a2 2 0 010 2.83 2 2 0 01-2.83 0l-.06-.06a1.65 1.65 0 00-1.82-.33 1.65 1.65 0 00-1 1.51V21a2 2 0 01-2 2 2 2 0 01-2-2v-.09A1.65 1.65 0 009 19.4a1.65 1.65 0 00-1.82.33l-.06.06a2 2 0 01-2.83 0 2 2 0 010-2.83l.06-.06a1.65 1.65 0 00.33-1.82 1.65 1.65 0 00-1.51-1H3a2 2 0 01-2-2 2 2 0 012-2h.09A1.65 1.65 0 004.6 9a1.65 1.65 0 00-.33-1.82l-.06-.06a2 2 0 010-2.83 2 2 0 012.83 0l.06.06a1.65 1.65 0 001.82.33H9a1.65 1.65 0 001-1.51V3a2 2 0 012-2 2 2 0 012 2v.09a1.65 1.65 0 001 1.51 1.65 1.65 0 001.82-.33l.06-.06a2 2 0 012.83 0 2 2 0 010 2.83l-.06.06a1.65 1.65 0 00-.33 1.82V9a1.65 1.65 0 001.51 1H21a2 2 0 012 2 2 2 0 01-2 2h-.09a1.65 1.65 0 00-1.51 1z" /></svg>
        </div> -->
    <!-- <img class="user-profile" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%281%29.png" alt="" class="account-profile" alt=""> -->
    <!-- </div>
</div> -->

    <div class="row m-2">
        <div class="app">
            <div class="wrapper">
                <div class="conversation-area col-md-4">
                    <h4 class="pl-6 mt-4">My Contacts</h4>
                    <div class="msg" *ngFor="let connection of connectionList" [style.background-color]="connection == selectedUser ? '#dee2e6' : ''" (click)="getMesList(connection)">
                        <img class="msg-profile" src="{{connection.profile == null ? 'assets/images/profile1.png' : connection.profile}}" alt="" />
                        <div class="msg-detail">
                            <div class="msg-username">{{connection.name}} {{connection.surname}}</div>
                        </div>
                        <i class="fa fa-angle-right custom-angle-right"></i>
                    </div>
                </div>
                <div class="chat-area col-md-8" style="border-left:1px solid #cecece;" #scrollMe>
                    <div class="chat-area-header">
                        <div class="chat-area-title"><img class="msg-profile" src="{{toUserProfile ? toUserProfile : 'assets/images/profile1.png'}}" alt=""> {{toUsername}} </div>
                    </div>
                    <div class="chat-area-main">
                        <div class="chat-msg " *ngFor="let item of getMessagesList.slice().reverse()" [ngClass]="item.status == 'Sent' ? 'owner' : ''">
                            <div class="chat-msg-profile">
                                <img class="chat-msg-img" src="{{item.fromUserProfilePath == null ? 'assets/images/profile1.png' : item.fromUserProfilePath}}" alt="" />
                                <div class="chat-msg-date">{{item.creationTime | dateAgo}}</div>
                            </div>
                            <div class="chat-msg-content">
                                <div class="chat-msg-text">{{item.subject}}</div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="chat-area-footer"> -->
                    <form class="chat-area-footer" [formGroup]="messageForm" (ngSubmit)="onFormSubmit()">
                        <input type="text" placeholder="Type something here..." formControlName="subject">
                        <button><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>