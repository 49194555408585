import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { Observable } from 'rxjs';
import { AppConsts } from '../../core/AppConsts';
import { EventModel } from '../models/event/event.model';
import { ResponseModel } from 'src/core/models/response/response.model';
import { EventListModel } from '../models/event/event-list.model';
import { EventFilter } from '../models/event/event-filter';
import { PagedResultDto } from 'src/core/models/PagedResultDto';
import { EventInput } from '@fullcalendar/angular';
import { EditEventModel } from '../models/event/edit-event.model';
import { BankDetailFilter } from '../models/UserBankDetail/get-user-bank.model';
import { CreateUserBankModel } from '../models/UserBankDetail/create-user-bank.model';
import { MasterDataDto } from 'src/core/models/master/MasterDataDto';

@Injectable({
    providedIn: 'root'
})
export class UserBankDetailService {

    constructor(
        private apiService: ApiService
    ) {

        this.wso2AmEndpoint = AppConsts.remoteServiceBaseUrl + "/app";
    }

    private wso2AmEndpoint: string;

    getUserBankList(filter: BankDetailFilter, userId: any) {
        return this.apiService.post(`${this.wso2AmEndpoint}/bank-detail/get-user-bank-detail/${userId}`, filter);
    }

    getCurrentUserBankList(filter: BankDetailFilter) {
        return this.apiService.post(`${this.wso2AmEndpoint}/bank-detail/get-current-user-bank-detail`, filter);
    }

    createUserBank(createUserBankModel: CreateUserBankModel = new CreateUserBankModel()): Observable<ResponseModel> {
        if (createUserBankModel.id != null && createUserBankModel.id != "") {
            return this.apiService.put(`${this.wso2AmEndpoint}/bank-detail/${createUserBankModel.id}`, createUserBankModel);
        } else {
            return this.apiService.post(`${this.wso2AmEndpoint}/bank-detail/`, createUserBankModel);
        }

    }

    getBank(id) {
        return this.apiService.get(`${this.wso2AmEndpoint}/bank-detail/${id}`);
    }

    getBankList(): Observable<MasterDataDto[]> {
        return this.apiService.get(`${this.wso2AmEndpoint}/bank-detail/bank`);
    }
}
